export const environment = {
  apiUrl: 'https://api.stage.ngin-staging.com/',
  bossUrl: 'https://boss-service.stage.ngin-staging.com/',
  coreUrl: 'https://login.stage.ngin-staging.com/core/',
  clientId: 'c8a5d5a5ed5e212e361148415691fc91', // todo change this
  cmsUrl: 'https://cms-service.stage.ngin-staging.com/',
  memDashUrl: 'https://memberships.stage.ngin-staging.com/support-dashboard',
  production: false,
  seBarJs: 'https://se-bar.stage.ngin-staging.com/se-bar.js',
  userUrl: 'https://user.stage.ngin-staging.com/',
  peopleAndOrgsLibraryUrl: 'https://people-and-orgs.ui.stage.ngin-staging.com/',
  uiThemes: 'https://s3.amazonaws.com/sportngin-snap-staging/ui_themes/assets/latest/images'
}
